@tailwind base;
@tailwind components;
@tailwind utilities;

.btn-primary {
  @apply text-center py-2 px-4 rounded-md shadow-sm border border-transparent text-sm font-medium text-white bg-black hover:bg-white hover:text-black hover:border-black
}

.text-link-primary {
  @apply whitespace-nowrap font-medium text-primary-reg hover:text-black
}

.btn-secondary {
  @apply text-center py-2 px-4 rounded-md shadow-sm text-sm font-medium text-black bg-gray-200 hover:bg-gray-300 hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-reg
}

.btn-small {
  @apply px-2.5 py-1.5 text-xs
}